<template>
    <div class="card mt-3 mx-3 list-item">
        <div class="card-body">
            <router-link :to="`/article/${article.id}`">
                <h5 class="card-title">{{article.title}}</h5>
            </router-link>
            <p class="card-text">{{translation.authors}}:
                <router-link v-for="(author,auidx) in article.authors" :key="auidx" :to="`/journal/${article.issn}/authors/${author.name}`">
                {{author.fullName}}<span v-if="article.authors.length > 1 && auidx < article.authors.length - 1">;</span>
                </router-link>
            </p>
            <div v-for="(items, key) of article.highlight" :key="`hl-h-${article.id}-${key}`">
                <h5 class="cart-title">{{_.startCase(key)}}</h5>
                <p class="card-text" v-for="(item, idx) of items" :key="`hl-p-${article.id}-${key}-${idx}`" v-html="item"></p>
            </div>
            <p class="card-text">{{translation.keywords}}: <strong v-if="article.keywords">{{article.keywords}}</strong><span class="text-muted">{{translation.noKeywords}}</span></p>
            <p class="card-text">
                <small class="text-muted">
                    {{translation.journal}}: 
                    <router-link :to="`/journal/${article.issn}/issue/${article.year}-${article.volume}-${article.volume}`">
                        {{article.journal}} {{article.volume}}({{article.issue}}) ({{article.year}})
                    </router-link>
                    {{translation.pages}}: {{article.pages.join('-')}}
                </small>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['article']
}
</script>


<style lang="scss">
em.search-hightlight {
    background-color: yellow;
}
</style>