<template>
  <div id="app">
    <Navigation />
    <transition :name="transitionName">
      <router-view/>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import SmallHeader from './components/Small-Header'
import Header from './components/Header'

Vue.component('Small-Header', SmallHeader)
Vue.component('Header', Header)

const ComponentContext = require.context('@content/documents', true, /\.md$/i)
const docs = []
ComponentContext.keys().forEach(req => docs.push(ComponentContext(req)) )


export default {
  name: 'App',
  data: () => ({
    transitionName: 'slide-left'
  }),
  components: {
    Navigation, Footer
  },
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  },
  created() {
    docs.filter(doc => doc.attributes.href && doc.attributes.lang).forEach(doc => this.document(doc))
  }
}
</script>