<template>
    <div v-if="newsItems.length">
        <h2>{{doc.meta.title}}</h2>
        <hr>
        <div class="card" v-for="item in newsItems" :key="`news-iten-${item.href}`">
            <img :src="item.meta.image" class="card-img-top" :alt="item.meta.title" v-if="item.meta.image">
            <div class="card-body">
                <h5 class="card-title">{{item.meta.title}}</h5>
                <p class="card-text">{{item.meta.description}}</p>
                <router-link :to="{name: 'page', params: {href: item.meta.href}}" class="btn btn-primary">Go somewhere</router-link>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>


export default {
    name: "NewsList",
    computed: {
        newsItems() {
            let items = this.hrefs('/news/*') || []
            return [].concat(items).sort((a,b) => b.meta.order - a.meta.order)
        },
        doc() {
            return this.hrefs('/news')
        }
    }
}
</script>